@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  scroll-behavior: smooth;
  background-color: #11071a; /* Same background color as your div */
  /* overflow-x: hidden; Prevent horizontal scrolling */
}

@keyframes gradientToNeon {
  0% {
    /* color: transparent; 
      background: linear-gradient(90deg, #6A5ACD, #ff57d1); 
       -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; */
  }
  50% {
  }
  100% {
    color: #220839;
    text-shadow: 0 0 10px #00f0ff;
    -webkit-text-stroke: 1px white;
  }
}

@layer utilities {
  .blur-border {
    position: relative;
    z-index: 1;
  }

  .blur-border::before {
    content: "";
    position: absolute;
    inset: -4px; /* Controls the border width */
    z-index: -1;
    background: linear-gradient(135deg, #d76d5e66, #134fe966);
    filter: blur(10px); /* Controls the blur effect */
    border-radius: inherit;
    opacity: 0.8;
  }
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  /* height: 100%; */
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent overlay */
  backdrop-filter: blur(200px); /* Adjust blur intensity */
  z-index: 999; /* Ensure it stays on top */
}

.menu-content {
  position: relative;
  z-index: 1000; /* Content stays above the blurred overlay */
}

.custom-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}

.letter {
  display: inline-block;
  animation: gradientToNeon 6s ease-out infinite; /* White border */
}

.neonText {
  color: #220839;
  text-shadow: 0 0 10px #00f0ff;
  -webkit-text-stroke: 1px white;
}

/* Adding delay classes */
.delay-200 {
  animation-delay: 0.2s;
}
.delay-400 {
  animation-delay: 0.4s;
}
.delay-600 {
  animation-delay: 0.6s;
}
.delay-800 {
  animation-delay: 0.8s;
}
.delay-1000 {
  animation-delay: 1s;
}
.delay-1200 {
  animation-delay: 1.2s;
}
.delay-1400 {
  animation-delay: 1.4s;
}
.delay-1600 {
  animation-delay: 1.6s;
}
.delay-1800 {
  animation-delay: 1.8s;
}
.delay-2000 {
  animation-delay: 2s;
}

@keyframes moving-border {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.moving-border {
  background: linear-gradient(
    90deg,
    white,
    transparent 30%,
    white 40%,
    transparent
  );
  background-size: 300%;
  animation: moving-border 3s linear infinite;
  padding: 1px; 
  border-radius: 20px; 
}

.card {
  margin: 0 auto;
  width: fit;
  background: black;
  text-align: center;
  border-radius: 12px;
  position: relative;
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.card::after,
.card::before {
  content: "";
  position: absolute;
  height: 115%;
  width: 105%;
  background-image: conic-gradient(
    from var(--angle),
    transparent 70%,
    red,
    #fe4d58,
    #4277ff,
    #6d11ff,
    blue
  );
  top: -2.5px;
  left: -2.5px;
  transform: -50% -50%;
  z-index: -1;
  padding: 5px;
  border-radius: 12px;
  animation: 3s spin linear infinite;
}
/* 
.card::before {
  filter: blur(1.5rem);
  opacity: 0.8;
} */

@keyframes spin {
  from {
    --angle: 0deg;
  }
  to {
    --angle: 360deg;
  }
}

/* modal styles  */

/* Modal content styling */
.modal-class {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(37, 31, 31, 0.5); /* Translucent background */
  padding: 10px;
  border-radius: 10px;
  outline: none;
  max-width: 80%;
  max-height: 80%;
  backdrop-filter: blur(10px); /* Applies the blur effect to the background */
}

/* Overlay styling */
.overlay-class {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Close button styling */
.close-modal-button {
  margin-top: 10px;
  background: red;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1); /* Makes the icon white */
  cursor: pointer;
}

select option:disabled {
  color: rgba(255, 255, 255, 0.5); /* Lighter color for disabled option */
  background-color: transparent;   /* Ensures background is consistent */
}
